<template>
  <div class="spread">
    <navheader :showNav="showNav" :yidongmenu="yidongmenu" :openHongbao="openHongbao"></navheader>
    <div class="spread-warp">
      <div class="roomleft-num">VIP福利</div>

      <div class="hist-list">
        <el-tabs type="border-card">
          <el-tab-pane label="VIP等级详情">
            <div class="bot">
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="name" label="用户名称" width="180">
                </el-table-column>
                <el-table-column prop="grade" label="当前VIP等级" width="180">
                </el-table-column>
                <el-table-column prop="upgrade" label="升级条件:累积充值满" width="180">
                </el-table-column>
                <el-table-column prop="recharge" label="当前充值流水" width="150">
                </el-table-column>
                <el-table-column prop="gap" label="升级下一级还需充值" width="180">
                </el-table-column>
                <el-table-column prop="ls" label="当前消费流水" width="180">
                </el-table-column>
                <el-table-column prop="status" label="是否达标" width="150">
                </el-table-column>
                <el-table-column prop="reward" label="奖励金币" width="100">
                </el-table-column>
                <el-table-column prop="give" label="奖励说明">
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="奖励列表">
            <div class="bot">
              <el-table :data="tableData1" style="width: 100%">
                <el-table-column prop="grade" label="VIP等级" width="130">
                </el-table-column>
                <el-table-column prop="upgrade" label="升级条件" width="130">
                </el-table-column>
                <el-table-column prop="reward" label="奖励金币"> </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  
  </div>

</template>

<script>
import { parse } from 'qs';
import navheader from "@/components/navheader.vue";
export default {
  components: { navheader},
	props: ["yidongmenu","showNav","openHongbao"],
  data() {
    return {
      totalSize: 0,
      page: 1,
      pageSize: 10,
      tableData: [],
      tableData1: [],
      totalSize: 0,
      pageSize: 10,
      totalSize1: 0,
      pageSize1: 10,
    };
  },
  mounted() {
    this.getskinToSteam(this.page);
    this.vip_reward(this.page);
  },
  methods: {

    getskinToSteam(page)
    {
      let param = {
        
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/Lucky/vipfuli", this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.totalSize = data.data.total;
            this.tableData = data.data.list;

          }
        });
    },
    //分页 所有记录
    currentChange(val) {
      this.getskinToSteam(val);
    },
  
    vip_reward(page)
    {
      let param = {
        
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/Lucky/vipreward", this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.totalSize1 = data.data.total;
            this.tableData1 = data.data.list;

          }
        });
    },

    goLucky() {
      this.$router.push({
        path: `/Ornament`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.spread {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;

  .spread-warp {
    padding: 16px;
  }
  .roomleft-num {
    color: #c3c3e2;
    font-size: 20px;
  }

  //表格
  .hist-list /deep/ .el-table th,
  .hist-list /deep/ .el-table tr {
    background-color: #2b2c37;
  }
  .hist-list /deep/ .el-table td,
  .hist-list /deep/ .el-table th.is-leaf {
    border-bottom: 1px solid #444659;
  }
  .hist-list /deep/ .el-table::before {
    height: 0;
  }
  .hist-list /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #212e3e !important;
  }
  /*.list /deep/ .cell {
    padding: 0;
    height: 60px;
    line-height: 60px;
  }*/
  .hist-list /deep/ .el-table__empty-block {
    background-color: #2b2c37;
  }

  //tabs
  //tabs
  .hist-list {
    margin-top: 20px;
  }
  .hist-list /deep/ .el-tabs--border-card {
    //overflow: hidden;
    background-color: #2b2c37;
    border: none;
    // border-radius: 5px;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header {
    border: none;
    background-color: #1a1c24;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    background-color: #2b2c37;
    border: none;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    background-color: #24252f;
    border: none;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    color: #e9b10e;
  }
}
 .payinfoBox {
  /deep/ .el-dialog__title {
    color: #808085;
  }
   /deep/ h3 {
    color: #eaeaea;
    //font-weight: normal;
    margin-bottom: 16px;
    font-size: 12px;

  }
  /deep/ .el-checkbox {
    color: #17b4ed;
  }
  /deep/ .btn-area {
    text-align: center;
    padding: 16px 0 8px 0;
  }
 }
</style>