<template>
  <div class="menus">
    <div
      v-for="(item, index) in menus"
      :key="item.routerName"
      :class="{
        'menu-center': index == 2,
      }"
      @click="$router.push({ name: item.routerName })"
    >
      <img
        v-if="activeName !== item.routerName"
        :src="item.img"
        alt=""
        srcset=""
      />
      <span v-if="activeName !== item.routerName">{{ item.name }}</span>

      <img
        v-if="activeName == item.routerName"
        :src="item.s_img"
        alt=""
        srcset=""
      />
      <span v-if="activeName == item.routerName" style="color: #ffc67b">{{
        item.name
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          img: require("@/assets/menus/1694837875796.png"),
          s_img: require("@/assets/menus/00.ee5fc70f.png"),
          name: "竞技场",
          routerName: "Lucky",
        },
        {
          s_img: require("@/assets/menus/11.d7303481.png"),
          img: require("@/assets/menus/1694838184454.png"),
          name: "追梦",
          routerName: "Ornament",
        },
        {
          s_img: require("@/assets/menus/22.cc268b0b.png"),
          img: require("@/assets/menus/1694838238918.png"),
          name: "首页",
          routerName: "Index",
        },
        {
          s_img: require("@/assets/menus/33.e9023b07.png"),
          img: require("@/assets/menus/1694838238906.png"),
          name: "ROLL房",
          routerName: "Arena",
        },
        {
          s_img: require("@/assets/menus/44.26a196c2.png"),
          img: require("@/assets/menus/1694838238929.png"),
          name: "我的",
          routerName: "Me",
        },
      ],
    };
  },
  computed: {
    activeName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="less" scoped>
.menus {
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  background-image: url("https://upcsgo.jmpjws.cn/img/footer-nav-bg.9679b5da.png");
  background-size: 100% 100%;
  display: flex;
  box-sizing: border-box;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 35px;
      height: 35px;
    }
    span {
      color: #00869a;
      font-size: 12px;
      margin-top: -5px;
    }
  }
  .menu-center {
    padding-bottom: 10px;
    margin-left: 5px;
  }
}
</style>
