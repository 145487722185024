<template>
	<div class="flexleft" v-if="!showNav">
	  <ul>
		<li
		  v-for="(item, index) in yidongmenu"
		  :key="index"
		  @click="goNavMenu(item.id, item.path,item.value)"
		>
		  <img
			:class="item.selState ? 'img1' : 'img2'"
			:src="item.selState ? item.img1 : item.img2"
			style="width: 14px;"
		  />
		  <div :class="item.selState ? 'item-val-bian' : 'item-val'" style="font-size: 11px;margin-top: 2px;">
			{{ item.value }}
		  </div>
		</li>
	  </ul>
	</div>
</template>

<script>
	export default {
		props: ["showNav","yidongmenu","openHongbao"],
		data() {
			return {
			}
		},
		methods:{
			//左侧导航
			goNavMenu(id, path, name) {
					if(name == "口令"){
						this.openHongbao();
						return;
					}
			  for (let i = 0; i < this.yidongmenu.length; i++) {
			    if (id == this.yidongmenu[i].id) {
			      this.yidongmenu[i].selState = true
			    } else {
			      this.yidongmenu[i].selState = false
			    }
			  }
			  this.$router.push({
			    path: `/${path}`,
			  })
			}
		}
	}
</script>

<style lang="less" scoped>
	.flexleft {
		display: none!important;
		height: 35px;
		// position: fixed;
		z-index: 999;
		width: 100%;
		display: flex;
		align-items: center;
		background-color: #191e2e;
          color: #848492;
		padding-bottom: 5px;
		ul{
			width: 100%;
			display: flex;
			flex-direction: row;
			padding: 0 12px;
			justify-content: space-between;
			li{
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.item-val-bian{
		color: #6CE5E8;
	}
</style>