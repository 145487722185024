<template>
  <div class="arena">
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
    <div class="arena-warp">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs">
        <el-tab-pane label="全部房间" name="first">
          <div class="list">
            <ul>
              <li v-for="(item, index) in list" :key="index">
                <div class="list-warp">
                  <div class="time">
                    开奖时间 :<span>{{ item.run_lottery_time }}</span>
                  </div>
                  <div class="tou-warp">
                    <!-- <img :src="item.img" /> -->
                    <div class="tou-warp-back"></div>
                  </div>
                  <div class="tou">
                    <img :src="item.img" />
                    <span>{{ item.type == 1 ? "官方" : "主播" }}</span>
                    <div class="name">{{ item.room_name }}</div>
                  </div>
                  <div class="img-container">
                    <div
                      class="img"
                      v-for="(item1, index1) in item.skin_list"
                      :key="index1"
                    >
                      <div class="img-box">
                        <img :src="item1.imageUrl" />
                        <span>{{ item1.price }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="num">
                    <div class="num-box">
                      <span>奖池:</span>
                      <span class="num-span">{{ item.pool }}</span>
                    </div>
                    <div class="num-box">
                      <span>件数:</span>
                      <span>{{ item.count }}</span>
                    </div>
                    <div class="num-box">
                      <span>人数:</span>
                      <span>{{ item.person_num }}</span>
                    </div>
                  </div>
                  <div class="clear"></div>
                  <div class="btn">
                    <div
                      class="btn-warp"
                      @click="jionRoom(item.id)"
                      v-if="item.status == 1"
                    >
                      <img
                        v-if="
                          item.condition_type == 1 || item.condition_type == 3
                        "
                        src="../assets/img/suo.png"
                      />
                      <span>加入房间</span>
                    </div>
                    <div class="btn-warp" v-if="item.status == 2">
                      <span @click="jionRoom(item.id)">已结束</span>
                    </div>
                  </div>

                  <div
                    class="back1"
                    v-if="item.status == 2"
                    @click="jionRoom(item.id)"
                  ></div>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>

        <el-tab-pane label="我参与的" name="second">
          <div class="list">
            <ul>
              <li v-for="(item, index) in list1" :key="index">
                <div class="list-warp">
                  <div class="time">
                    开奖时间 :<span>{{ item.run_lottery_time }}</span>
                  </div>
                  <div class="tou-warp">
                    <img :src="item.img" />
                    <div class="tou-warp-back"></div>
                  </div>
                  <div class="tou">
                    <img :src="item.img" />
                    <span>{{ item.type == 1 ? "官方" : "主播" }}</span>
                    <div class="name">{{ item.room_name }}</div>
                  </div>
                  <div class="img-container">
                    <div
                      class="img"
                      v-for="(item1, index1) in item.skin_list"
                      :key="index1"
                    >
                      <div class="img-box">
                        <img :src="item1.imageUrl" />
                        <span>{{ item1.price }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="num">
                    <div class="num-box">
                      <span>奖池:</span>
                      <span class="num-span">{{ item.pool }}</span>
                    </div>
                    <div class="num-box">
                      <span>件数:</span>
                      <span>{{ item.count }}</span>
                    </div>
                    <div class="num-box">
                      <span>人数:</span>
                      <span>{{ item.person_num }}</span>
                    </div>
                  </div>
                  <div class="clear"></div>
                  <div class="btn">
                    <div class="btn-warp" v-if="item.status == 1">
                      <span @click="jionRoom(item.id)">查看房间</span>
                    </div>
                    <div class="btn-warp" v-if="item.status == 2">
                      <span @click="jionRoom(item.id)">已结束</span>
                    </div>
                  </div>
                  <div
                    class="back1"
                    v-if="item.status == 2"
                    @click="jionRoom(item.id)"
                  ></div>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <myhomebot></myhomebot>

    <div style="height: 72px" v-if="$store.state.mobile">
      <BottomMenus />
    </div>
  </div>
</template>
<script>
import BottomMenus from "../views/menus.vue";

import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import navheader from "@/components/navheader.vue";
export default {
  components: { myhomebot, BottomMenus, myinform, navheader },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      activeName: "first",
      list: [],
      list1: [],
    };
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name == "first") {
        this.getList();
      } else {
        this.getMyList();
      }
    },
    //免费皮肤房间列表
    getList() {
      let _this = this;
      let param = {
        page: 1,
        pageSize: 50,
      };
      _this.$axios
        .post("index/Free/freeRoomList", _this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == 1) {
            this.list = data.data.list;
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].person_num == null) {
                this.list[i].person_num = 0;
              }
              if (this.list[i].count == null) {
                this.list[i].count = 0;
              }
              if (this.list[i].pool == null) {
                this.list[i].pool = 0;
              }
            }
          }
        });
    },
    //我参与的房间列表
    getMyList() {
      let _this = this;
      let param = {
        page: 1,
        pageSize: 10,
      };
      _this.$axios
        .post("index/Free/myFreeRoom", _this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == 1) {
            this.list1 = data.data.list;
            for (let i = 0; i < this.list1.length; i++) {
              if (this.list1[i].person_num == null) {
                this.list1[i].person_num = 0;
              }
              if (this.list1[i].count == null) {
                this.list1[i].count = 0;
              }
              if (this.list1[i].pool == null) {
                this.list1[i].pool = 0;
              }
            }
          }
        });
    },
    //加入房间
    jionRoom(id) {
      if (!this.$store.state.token) {
        this.$store.commit("getLogin", true);
        return;
      }
      this.$router.push({
        path: `/ArenaRoom`,
        query: {
          id: id,
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "ninefont";
  src: url("../assets/font/ninefont.ttf");
}
.arena {
  width: 100;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  .arena-warp {
    min-height: 100vh;
    padding: 16px 50px;
    width: 72.92vw;
    margin: 4.43vw auto 0px;
    background: rgba(0, 0, 0, 0.4);
    padding: 1.15vw 2.08vw 2.08vw;
    box-sizing: border-box;
    .tabs /deep/ .el-tabs__content .list {
      width: 100%;
      // display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .tabs /deep/ .el-tabs__item.is-active {
      color: #c3c3e2;
    }
    .tabs /deep/ .el-tabs__item {
      color: #848492;
      font-size: 20px;
    }
    .tabs /deep/ .el-tabs__nav-wrap .el-tabs__active-bar {
      background-color: unset;
    }
    .tabs /deep/ .el-tabs__nav-wrap #tab-first.is-active {
      background: url("../assets/95/tab1active.png") 50% center / 100% no-repeat;
      color: rgb(32, 33, 43);
    }
    .tabs /deep/ .el-tabs__nav-wrap #tab-second.is-active {
      background: url("../assets/95/tabactive.png") 50% center / 100% no-repeat;
      color: rgb(32, 33, 43);
    }
    .tabs /deep/ .el-tabs__nav-wrap #tab-second,
    .tabs /deep/ .el-tabs__nav-wrap #tab-first {
      padding: 0 50px;
      background: url("../assets/95/tab1bg.png") 50% center / 100% no-repeat;
      position: relative;
      z-index: 1;
      font-size: 12px;
      color: #fff;
      font-weight: 700;
    }
    .tabs /deep/ .el-tabs__nav-wrap #tab-second {
      background: url("../assets/95/tabbg.png") 50% center / 100% no-repeat;
    }
    .tabs /deep/ .el-tabs__nav-wrap #tab-second {
      margin-left: -1.1vw;
      color: #fff;
    }
    .tabs /deep/ .el-tabs__nav-wrap::after {
      background-color: #ffc400;
    }

    .tabs {
      .list {
        ul {
          margin: 0 -8px;
          li {
            width: 50%;
            float: left;
            .list-warp {
              margin: 8px;
              border-radius: 5px;
              overflow: hidden;
              position: relative;
              padding: 0 15px;
              background: url("../assets/95/room-back.png") no-repeat center;
              background-size: 100% 100%;
              box-sizing: border-box;
              position: relative;
              .tou-warp {
                width: 100%;
                height: 130px;
                position: relative;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
                .tou-warp-back {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 130px;
                }
              }

              .back1 {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 0;
                left: 0;
              }
              .img-container {
                display: flex;
                justify-content: space-between;
                .img {
                  margin: 0;
                  padding: 0;
                }
              }
              .tou {
                margin-top: -125px;
                position: relative;
                z-index: 66;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                img {
                  width: 35px;
                  height: 35px;
                  border-radius: 50%;
                  border: 1px solid #ae23c6;
                }

                span {
                  color: #ffc400;
                  font-weight: 700;
                  font-size: 13px;
                  padding: 0 6px;
                  // position: absolute;
                  // background-color: #ae23c6;
                  border-radius: 3px;
                  top: 20px;
                  left: 50px;
                }
              }
              .name {
                position: relative;
                z-index: 66;
                // padding: 17px 40px;
                font-size: 18px;
                font-family: ninefont;
                color: #fff;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .num {
                position: relative;
                z-index: 66;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .num-box {
                  // display: flex;
                  // flex-direction: column;
                  // align-items: center;
                  margin: 0;
                  padding: 0;
                  width: 8.54vw;
                  text-align: center;
                  span:nth-child(1) {
                    padding-right: 5px;
                  }
                  span {
                    color: #fff;
                    font-size: 14px;
                  }
                  span:last-child {
                    margin-top: 5px;
                  }
                  .num-span {
                    color: #e9b10e;
                  }
                }
              }
              .img {
                padding: 0 16px;
                margin-top: 5px;
                .img-box {
                  // width: 33.33%;
                  width: 8.54vw;
                  // float: left;
                  background-image: url("../assets/95/borderbg.png");
                  background-size: 100% 100%;
                  position: relative;
                  text-align: center;
                  // margin-right: 1.04vw;
                  // margin-right: 2px;
                  padding: 5px 0;

                  img {
                    width: 70%;
                    height: 45px;
                  }
                  span {
                    position: absolute;
                    top: 20px;
                    left: 50%;
                    margin-left: -25px;
                    color: #fff;
                    padding: 0 10px;
                    border-radius: 20px;
                    background-color: rgba(0, 0, 0, 0.3);
                    font-size: 10px;
                  }
                }
                .img-box:first-child {
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                }
                .img-box:last-child {
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
              }
              .btn {
                padding: 0 16px;
                margin-top: 20px;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                .btn-warp {
                  display: flex;
                  align-items: center;
                  border: 1px solid #ffc400;
                  padding: 5px 25px;
                  border-radius: 20px;

                  img {
                    width: 16px;
                    height: 16px;
                  }
                  span {
                    margin-left: 10px;
                    font-size: 14px;
                    font-weight: 700;
                    color: #ffc400;
                  }
                }
                .btn-warp:hover {
                  cursor: pointer;
                  border: 1px solid#e9b10e;
                  background-color: #e9b20e31;
                }
                .btn-warp:hover span {
                  color: #e9b10e;
                }
              }

              .time {
                margin-top: 10px;
                font-size: 16px;
                color: #ffc400;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .arena {
    margin-top: 46px;
    .arena-warp {
      background: unset;
      width: 100%;
      padding: 0;
      .tabs /deep/.el-tabs__nav-wrap #tab-second {
        margin-left: -18px;
      }
      .tabs /deep/.el-tabs__nav-wrap.is-top {
        height: 35px;
        line-height: 35px;
      }
      .tabs {
        .list {
          ul {
            li {
              .list-warp {
                .btn {
                  margin: 10px 0;
                  .btn-warp span {
                    font-size: 12px;
                  }
                }
                .tou {
                  margin-top: -140px;
                }
                .num {
                  justify-content: space-around;
                  .num-box {
                    width: unset;
                    span {
                      font-size: 12px;
                    }
                  }
                }
                width: 92%;
                margin: 0 auto;
                .img-container .img {
                  width: 31%;
                  .img-box {
                    width: 100%;
                    padding: 3px 0;
                  }
                }
                .time {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
